<template>
	<div>

		<div class="ds" v-if="code==='0'">
			<div style="padding-top: 20px;margin-bottom: 15px;" class="ds2">
				<div class="dn"
					style="height: 40px;display: inline-block;color: #0088cc;line-height: 20px;padding-left: 7px;font-size: 22px;">
					<a>{{ introducedata.headline }}</a>
				</div>
				<div style="line-height: 30px;color: #333;text-align: justify;padding-left: 15px;padding-right: 15px;border-top: 1px solid #c2cfe2;padding-top: 15px;"
					class="imgs" v-html="introducedata.content">
				</div>
				<div style="font-weight: 600;text-align: right;padding-top: 25px;padding-right: 15px;">
					{{ introducedata.source }}
				</div>
			</div>
		</div>

		<!-- 给个标识,让上面的或者下面的显示  -->
		<div class="ds" v-if="code==='1'">
			<div style="padding-top: 20px;margin-bottom: 15px;" class="ds2">
				<div class="dn"
					style="height: 40px;display: inline-block;color: #0088cc;line-height: 20px;padding-left: 7px;font-size: 22px;">
					<a>{{ introduceheadline }}</a>
				</div>
				<div class="massage">
					<ul>
						<li v-for="(item,key) in introducedatas" :key="key">
							<div style="text-align: center;border-top: 1px solid #c2cfe2;padding-top: 10px;">
								<img :src="item.img" alt="" style="width: 60%;">
								<!-- http://www.chinaesa.org.cn/upload/201805/10/201805101936347575.jpg -->
							</div>
							<div>
								<h2 style="text-align: center;"><a @click="manyclick(item)" style="font-size: 18px;">{{ item.title }}</a>
								</h2>
								<div style="padding: 10px;">
									<p v-html="item.content"></p>
								</div>
								<!-- <p>
									<span>{{ item.creatTime }}</span>
									<el-button style="float: right;padding: 6px;width: 100px;" type="primary"
										@click="manyclick(item)">查看更多
									</el-button>
								</p> -->
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div v-if="details" class="ds">
			<div style="padding-top: 20px;margin-bottom: 15px;" class="ds2">
				<el-page-header @back="goBack" :content="headline">
				</el-page-header>
				<div style="text-align: center;padding-top: 20px;font-weight: bold;font-size: 25px;">
					{{ title }}
				</div>
				<el-divider></el-divider>
				<div style="text-align: center;">
					<el-image :src="img" style="vertical-align: top;max-width: 100%;width: 80%;"></el-image>
				</div>
				<div style="line-height: 30px;color: #333;text-align: justify;padding-left: 15px;padding-right: 15px;padding-top: 15px;"
					v-html="content"></div>
				<div style="font-weight: 600;text-align: right;padding-top: 25px;padding-right: 15px;">{{ time }}</div>
				<!-- <div style="padding-top: 10px;text-align: right;padding-bottom: 25px;font-weight: 600;">{{ source }}
					</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				introduceheadline: '',
				maincontentdatas: {},
				code: '0',
				introducedata: {},
				introducedatas: [],
				details: false,
				title: '',
				content: '',
				time: '',
				headline: '',
				source: '',
				img: '',
			}
		},
		methods: {
			onAct(data) {
				if (data.headline === "领导介绍") {
					this.$post("/industry/selectleader", {
						headline: data.headline
					}).then(resp => {
						this.introducedatas = resp.dataList
						this.introduceheadline = resp.dataList[0].headline
					})
					this.code = '1'
					this.details = false
				} else {
					this.$post("/industry/selectheadline", {
						headline: data.headline
					}).then(resp => {
						this.introducedata = resp.data
					})
					this.code = '0'
					this.details = false
				}
			},
			manyclick(data) {
				this.details = true
				this.code = '2'
				this.title = data.title
				this.content = data.content
				this.time = data.creatTime
				this.headline = data.headline
				this.source = data.source
				this.img = data.img
			},
			goBack() {
				this.details = false
				this.code = '1'
			},
		},
		watch: {
			$route() {
				if (this.$route.query.headlinedata.headline === "领导介绍") {
					this.$post("/industry/selectleader", {
						headline: this.$route.query.headlinedata.headline
					}).then(resp => {
						this.introducedatas = resp.dataList
						this.introduceheadline = resp.dataList[0].headline
					})
					this.code = '1'
					this.details = false
				} else {
					this.$post("/industry/selectheadline", {
						headline: this.$route.query.headlinedata.headline
					}).then(resp => {
						this.introducedata = resp.data
					})
					this.code = '0'
					this.details = false
				}
			}
		},
		created() {
			if (this.$route.query.headlinedata.headline === "领导介绍") {
				this.$post("/industry/selectleader", {
					headline: this.$route.query.headlinedata.headline
				}).then(resp => {
					this.introducedatas = resp.dataList
					this.introduceheadline = resp.dataList[0].headline
				})
				this.code = '1'
				this.details = false
			} else {
				this.$post("/industry/selectheadline", {
					headline: this.$route.query.headlinedata.headline
				}).then(resp => {
					this.introducedata = resp.data
				})
				this.code = '0'
				this.details = false
			}
		}
	}
</script>

<style scoped="scoped">
	.ds {
		padding-bottom: 0 !important;
		margin-top: 10px !important;
		background: #FFF !important;
		min-height: 500px;
		width: 100%;
	}

	.ds2 {
		width: 100% !important;
		min-height: 540px;
		background: #fff;
		margin: auto;
		margin: 0 auto;
		padding-top: 20px;
		padding-bottom: 20px;
		border: 1px solid #dcdcdc;
		width: 95% !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.cont-img p img {
		width: 100%;
	}

	.imgs img {
		width: 100%;
	}
</style>
